import { useEffect, useRef, useState } from 'react';
import LayoutContainer from '../components/Layout/Container';
import Loading from '../components/Loading';
import { useParams, useNavigate } from 'react-router-dom';
import useRequest from '../hooks/useRequest';
import { ArbolResponse } from '../interfaces';
import IMGModal from '../components/IMGModal/IMGModal';

const url = process.env.REACT_APP_BASE_BASE_URL;

export default function View() {
  const { reqBase } = useRequest();
  const { id } = useParams();
  const navigate = useNavigate();

  const modalBtnRef = useRef<any>();

  const [active, setActive] = useState(1);
  const [data, setData] = useState<ArbolResponse | null>(null);
  const [error, setError] = useState(false);

  const [perimetros, setPerimetros] = useState([]);

  const getData = async () => {
    try {
      const res = await reqBase.get(`front/arboles/${id}`);
      setData(res.data);
      setPerimetros(JSON.parse(res.data.arb_text_perimetro_alt_pecho));
    } catch (error) {
      console.log(error);
      setError(true);
    }
  };

  const getTotal = (arr: any) => {
    if (arr.lenght === 0) return 0;

    return arr.reduce((prev: any, current: any) => {
      const value = prev + parseFloat(current?.per);
      return value;
    }, 0);
  };

  const handleImage = () => {
    modalBtnRef.current.click();
  };

  const motivosTable = {
    a: 'Por ser nativo o endémica',
    b: 'Rareza en zona de estudio',
    c: 'Forma poco habitual entre los demás ejemplares de la misma especie',
    d: 'Por su avanzada edad a la que acompañara un magnífico porte',
    e: 'Por sus notables dimensiones',
    f: 'Por su localización',
    g: 'Ejemplar simbólico o emblemático',
    h: 'Ejemplar histórico',
    i: 'Ejemplar ligado a la tradición del lugar',
    j: 'Tener la condición de generador de semillas',
    k: 'Ser ejemplar con características motivo de una investigación de interés científico, ecológico, etc',
    l: 'Albergar especies de fauna o flora amenazadas de extinción'
  };

  useEffect(() => {
    getData();

    //eslint-disable-next-line
  }, [id]);

  if (!data) {
    return (
      <LayoutContainer>
        <Loading />
      </LayoutContainer>
    );
  }

  if (!data && error) {
    return (
      <LayoutContainer>
        <h3 className="text-center w-100 text-danger text-uppercase">
          Un error ha ocurrido, no se pudo encontrar el árbol con id [ {id} ] o
          el servidor no responde, inténtalo más tarde
        </h3>
      </LayoutContainer>
    );
  }

  return (
    <LayoutContainer>
      <h2 className="text-center w-100 mb-5">Vista de árbol patrimonial</h2>

      <IMGModal id={data?.arb_int_id} />

      <button
        type="button"
        className="d-none"
        data-bs-toggle="modal"
        data-bs-target="#imageModal"
        ref={modalBtnRef}
      />

      <button
        onClick={() => navigate('/')}
        type="button"
        className="btn btn-secondary w-auto me-auto mb-3"
      >
        <i className="fas fa-caret-left me-2"></i>
        Volver a la lista
      </button>

      <section className="flex-grow-1 animate__animated animate__fadeInLeft">
        <div className="row mx-0 w-100">
          <div className="col-12 col-lg-4 card p-0 rounded-0">
            <div className="card-header">
              <h5 className="mb-0 w-100 text-center">Fotografía</h5>
            </div>
            <div className="card-body d-flex justify-content-center">
              <img
                src={`${url}front/arboles/${data.arb_int_id}/descargar`}
                alt=""
                height="330"
                onClick={handleImage}
                style={{ cursor: 'pointer' }}
              />
            </div>
          </div>

          <div className="col-12 col-lg-8 p-0 card rounded-0">
            <div className="card-header">
              <h5 className="mb-0 w-100 text-center">Información</h5>
            </div>
            <div className="card-body d-flex flex-column">
              <div className="w-100 d-flex gap-2 justify-content-center align-items-center mb-3 flex-wrap">
                <button
                  onClick={() => setActive(1)}
                  className={`btn btn-${
                    active === 1 ? 'secondary' : 'outline-secondary'
                  }`}
                >
                  Motivo patrimonialidad
                </button>

                <button
                  onClick={() => setActive(2)}
                  className={`btn btn-${
                    active === 2 ? 'secondary' : 'outline-secondary'
                  }`}
                >
                  Ubicación
                </button>

                <button
                  onClick={() => setActive(3)}
                  className={`btn btn-${
                    active === 3 ? 'secondary' : 'outline-secondary'
                  }`}
                >
                  Biometría
                </button>

                {(data?.arb_text_aporte_gente ||
                  data?.arb_text_propietario ||
                  data?.arb_text_accesos) && (
                  <button
                    onClick={() => setActive(4)}
                    className={`btn btn-${
                      active === 4 ? 'secondary' : 'outline-secondary'
                    }`}
                  >
                    Referencias
                  </button>
                )}
              </div>

              {active === 1 && (
                <div className="d-flex flex-column justify-content-center flex-grow-1">
                  {data?.arb_char_motivo.split(',').map((item: any) => {
                    //@ts-ignore
                    return <p className="text-center">{motivosTable[item]}</p>;
                  })}
                </div>
              )}

              {active === 2 && (
                <div className="d-flex align-items-center flex-grow-1">
                  <div className="row mx-0 w-100">
                    <span className="col-6 fw-bold text-end">Dirección:</span>
                    <span className="col-6">{data?.arb_text_direccion}</span>

                    {data?.arb_text_ref_cercanas !== '' ? (
                      <>
                        <span className="col-6 fw-bold text-end">
                          Referencias cercanas:
                        </span>
                        <span className="col-6">
                          <span className="tipotitulo">
                            {data?.arb_text_ref_cercanas}
                          </span>
                        </span>
                      </>
                    ) : (
                      ''
                    )}

                    <span className="col-6 fw-bold text-end">
                      Coordenadas X:
                    </span>
                    <span className="col-6">
                      {data?.arb_text_coor_x
                        ? parseFloat(
                            data?.arb_text_coor_x.replace(',', '.')
                          ).toFixed(4)
                        : '-'}
                    </span>
                    <span className="col-6 fw-bold text-end">
                      Coordenadas Y:
                    </span>
                    <span className="col-6">
                      {data?.arb_text_coor_y
                        ? parseFloat(
                            data?.arb_text_coor_y.replace(',', '.')
                          ).toFixed(4)
                        : '-'}
                    </span>
                  </div>
                </div>
              )}

              {active === 3 && (
                <div className="d-flex align-items-center flex-grow-1">
                  <div className="row mx-0 w-100">
                    <span className="col-6 fw-bold text-end">
                      Altura del fuste:
                    </span>
                    <span className="col-6">
                      {data?.arb_float_alt_fuste} metros
                    </span>
                    <span className="col-6 fw-bold text-end">
                      Altura total:
                    </span>
                    <span className="col-6">
                      {data?.arb_float_alt_total} metros
                    </span>
                    {perimetros.length > 1 && (
                      <>
                        <span className="col-6 fw-bold text-end">Troncos:</span>
                        <span className="col-6">{perimetros.length}</span>
                      </>
                    )}
                    <span className="col-6 fw-bold text-end">
                      Perímetro a la altura del pecho en ({perimetros.length})
                      tronco(s):
                    </span>
                    <span className="col-6">
                      {getTotal(perimetros).toFixed(4)} metros
                    </span>
                    <span className="col-6 fw-bold text-end">
                      Diámetro a la altura del pecho en ({perimetros.length})
                      tronco(s):
                    </span>
                    <span className="col-6">
                      {(getTotal(perimetros).toFixed(4) / Math.PI).toFixed(4)}{' '}
                      metros
                    </span>
                    <span className="col-6 fw-bold text-end">
                      Diámetro máximo de la copa:
                    </span>
                    <span className="col-6">
                      {data?.arb_float_diametro_max_copa} metros
                    </span>
                  </div>
                </div>
              )}

              {active === 4 && (
                <div className="d-flex align-items-center flex-grow-1">
                  <div className="row mx-0 w-100">
                    {data?.arb_text_aporte_gente && (
                      <>
                        <span className="col-6 fw-bold text-end">
                          Aporte gente:
                        </span>
                        <span className="col-6">
                          {data?.arb_text_aporte_gente}
                        </span>
                      </>
                    )}
                    {data?.arb_text_propietario && (
                      <>
                        <span className="col-6 fw-bold text-end">
                          Propietario:
                        </span>
                        <span className="col-6">
                          {data?.arb_text_propietario}
                        </span>
                      </>
                    )}
                    {data?.arb_text_accesos && (
                      <>
                        <span className="col-6 fw-bold text-end">Accesos:</span>
                        <span className="col-6">{data?.arb_text_accesos}</span>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </LayoutContainer>
  );
}
